import React from 'react'
import PropTypes from 'prop-types';
import Slider from "react-slick"
import { Link } from "gatsby"

export class ServicesCarouselSlice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            slideIndex: 0,
            updateCount: 0,
        }

        this.linkClick = this.linkClick.bind(this)
    }

    linkClick = event => {
        this.slider.slickGoTo(event.target.dataset.slide)
    }

    render() {
        const primaryData = this.props.slice.primary;
        const itemsData = this.props.slice.items;

        if (itemsData) {
            const settings = {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3500,
                fade: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                speed: 1000,
                afterChange: (index) => {
                    this.setState(state => ({updateCount: state.updateCount + 1}));
                },
                beforeChange: (current, next) => {
                    this.setState({slideIndex: next});
                }
            };
            return (
                <div className="c-services-carousel">
                    <div className="c-services-carousel__header-container">
                        <div className="container">
                            <div className="row">
                                <div className="c-services-carousel__header">
                                    {primaryData.servicescarousel_subtitle.text ? 
                                        <div className="c-services-carousel__subtitle">{primaryData.servicescarousel_subtitle.text}</div> : null}
                                    {primaryData.servicescarousel_title.text ? 
                                        <h2 className="c-services-carousel__title">{primaryData.servicescarousel_title.text}</h2> : null}
                                    {primaryData.servicescarousel_summary.text ? 
                                        <p className="c-services-carousel__summary">{primaryData.servicescarousel_summary.text}</p> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="c-services-carousel__navigation">
                                {itemsData.map((service, i) => {
                                    if (service.servicescarousel_title.text &&  
                                        service.servicescarousel_link.url) {
                                      return (
                                        <button
                                            key={i} 
                                            onClick={this.linkClick}
                                            data-slide={i} 
                                            className={this.state.slideIndex === i ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                            {service.servicescarousel_navigationtitle.text}
                                        </button>
                                      )
                                    }
                                    else {
                                      return null
                                    }
                                })}
                            </div>
                            <div className="c-services-carousel__items">
                                <Slider ref={slider => (this.slider = slider)} {...settings}>
                                    {itemsData.map((service, i) => {
                                        if (service.servicescarousel_title.text && 
                                            service.servicescarousel_link.url) {
                                                const defaultImage =  service.servicescarousel_image !== null && 
                                                                        service.servicescarousel_image !== undefined && 
                                                                        service.servicescarousel_image.url !== null ? service.servicescarousel_image.url : "";
                                                const circleImage =  service.servicescarousel_circleimage !== null && 
                                                                        service.servicescarousel_circleimage !== undefined && 
                                                                        service.servicescarousel_circleimage.url !== null ? service.servicescarousel_circleimage.url : "";
                                                const fullscreenImage = service.servicescarousel_fullscreenimage !== null && 
                                                                        service.servicescarousel_fullscreenimage !== undefined && 
                                                                        service.servicescarousel_fullscreenimage.url !== null ? service.servicescarousel_fullscreenimage.url : "";
                                            return (
                                                <div key={i}>
                                                    <div className="c-services-carousel__item">
                                                        <Link to={service.servicescarousel_link.url} className="c-services-carousel__item-content-container">
                                                            <div className="c-services-carousel__item-content">
                                                                <h3 className="c-services-carousel__item-title">{service.servicescarousel_title.text}</h3>
                                                                {service.servicescarousel_summary.text ? 
                                                                    <p className="c-services-carousel__item-summary">{service.servicescarousel_summary.text}</p> : null}
                                                            </div>
                                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                                        </Link>
                                                        {defaultImage !== undefined && defaultImage !== "" ? 
                                                            <img src={`${defaultImage}&w=800`} alt="" className="c-services-carousel__item-media" loading="lazy" /> : null}
                                                        {circleImage !== undefined && circleImage !== "" ? 
                                                            <div className="c-services-carousel__item-circle">
                                                                <img src={`${circleImage}&w=800`} alt="" loading="lazy" /> 
                                                            </div> : null}
                                                        {fullscreenImage !== undefined && fullscreenImage !== "" ? 
                                                            <img src={`${fullscreenImage}&w=1200`} alt="" className="c-services-carousel__item-fullscreen-media" loading="lazy" /> : null}
                                                        {/* If no image has been added, ensure there is a default rendered. */}
                                                        {(defaultImage === undefined || defaultImage === "") && 
                                                        (circleImage === undefined || circleImage === "") && 
                                                        (fullscreenImage === undefined || fullscreenImage === "") ?
                                                            <div className="c-services-carousel__item-circle">
                                                                <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                                            </div> : null}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return null
    }
}

ServicesCarouselSlice.propTypes = {
    slice: PropTypes.object
}

export default ServicesCarouselSlice