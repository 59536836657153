import React from 'react'
import Slider from "react-slick"

export const HowItWorksCarouselSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;
  
    if (itemsData) {
        const settings = {
            appendDots: dots => (
                <div>
                    <div className="row">
                        <div className="c-howitworks-carousel__dots">
                            <ul>{dots}</ul>
                        </div>
                    </div>
                </div>
            ),
            centerMode: true,
            centerPadding: "325px",
            dots: true,
            infinite: true,
            responsive: [
                {
                    breakpoint: 1399,
                    settings: {
                        centerPadding: "275px",
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        centerPadding: "225px",
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        centerPadding: "175px",
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerPadding: "0",
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 500
        };

        return (
            <div className="c-howitworks-carousel">
                <div className="container">
                    {primaryData.howitworkscarousel_title.text ? 
                        <div className="row">
                            <div className="c-howitworks-carousel__header">
                                <h2 className="c-howitworks-carousel__title">{primaryData.howitworkscarousel_title.text}</h2>
                            </div>
                        </div> : null}
                    <div className="row">
                        <div className="c-howitworks-carousel__container">
                            <Slider {...settings}>
                            {itemsData.map((item, i) => {
                                if (item.howitworkscarousel_title.text && item.howitworkscarousel_summary.text) {
                                    return (
                                        <div key={i} className="c-howitworks-carousel__item">
                                            <div className="c-howitworks-carousel__item-no">{i+1}</div>
                                            <h3 className="c-howitworks-carousel__item-title">{item.howitworkscarousel_title.text}</h3>
                                            <p className="c-howitworks-carousel__item-summary">{item.howitworkscarousel_summary.text}</p>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }

  return null
}