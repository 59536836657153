import React from 'react'

export const WhyWorkWithUsSlice = ({ slice }) => {
  const primaryData = slice.primary;
  
    if (primaryData.whyworkwithus_point1.text && 
        primaryData.whyworkwithus_point2.text && 
        primaryData.whyworkwithus_point3.text) {
        return (
            <div className="c-why-work">
                <div className="container">
                    {primaryData.whyworkwithus_title.text ? 
                        <div className="row">
                            <h2 className="c-why-work__title">{primaryData.whyworkwithus_title.text}</h2>
                        </div> : null}
                    <div className="row">
                        <div className="c-why-work__content">{primaryData.whyworkwithus_point1.text}</div>
                        <div className="c-why-work__separator">
                            <svg fill="none" viewBox="0 0 34 35"><path d="M.138 17.514H33.57M16.856 34.198V.832" stroke="#999" strokeWidth="5" strokeMiterlimit="10"/></svg>
                        </div>
                        <div className="c-why-work__content">{primaryData.whyworkwithus_point2.text}</div>
                        <div className="c-why-work__separator">
                            <svg fill="none" viewBox="0 0 34 20"><path d="M.5 3.28h33.433M.5 16.862h33.433" stroke="#999" strokeWidth="5" strokeMiterlimit="10"/></svg>
                        </div>
                        <div className="c-why-work__result">{primaryData.whyworkwithus_point3.text}</div>
                    </div>
                </div>
            </div>
        )   
    }

  return null
}