import React from 'react'

export const UseCasesSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;
  
    if (itemsData) {
        return (
            <div className="c-use-cases">
                <div className="container">
                    <div className="row">
                        <div className="c-use-cases__container">
                            <div className="row">
                                <div className="c-use-cases__content-container">
                                    <h2 className="c-use-cases__content-title">{primaryData.usecases_title.text}</h2>
                                    <ul className="c-use-cases__content">
                                        {itemsData.map((item, i) => {
                                            if (item.usecases_usecase.text) {
                                                return (
                                                    <li key={i}>{item.usecases_usecase.text}</li>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        })}
                                    </ul>
                                </div>
                                {primaryData.usescases_link.url && primaryData.usecases_link_text.text ?
                                    <div className="c-use-cases__link-container">
                                        <a href={primaryData.usescases_link.url} className="c-use-cases__link c-btn c-btn--text">{primaryData.usecases_link_text.text}</a>
                                    </div> : null                                
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }

  return null
}