import React from 'react'
import useInView from "react-cool-inview";

export const ListingSlice = ({ slice }) => {
    const primaryData = slice.primary;
    const itemsData = slice.items;

    const ListingItem = ({ title, imageUrl }) => {
        const { observe, inView } = useInView();
        return (
            <div className="c-listing__item" ref={observe}>
                <div className={inView ? "c-listing__item-container is-inview" : "c-listing__item-link"}>
                    <div className="c-listing__item-media-container">
                        <div className="c-listing__item-media-wrapper">
                            <img src={`${imageUrl}&w=500`} alt="" className="c-listing__item-media" loading="lazy"/>
                        </div>
                    </div>
                    <h3 className="c-listing__item-title">{title}</h3>
                </div>
            </div>
        )
    }
  
    if (itemsData) {
        return (
            <div className="c-listing">
                <div className="container">
                    <div className="row">
                        <div className="c-listing__header">
                            {primaryData.listing_title.text ? 
                                <h2 className="c-listing__header-title">{primaryData.listing_title.text}</h2> : null}
                            <div className="c-listing__header-link-container">
                                {primaryData.listing_buttonlink.url && primaryData.listing_buttontext.text ?
                                    <a href={primaryData.listing_buttonlink.url} className="c-listing__header-link c-btn c-btn--text">{primaryData.listing_buttontext.text}</a> : null}
                                {primaryData.listing_button2link != null && primaryData.listing_button2link.url && primaryData.listing_button2text.text ?
                                    <a href={primaryData.listing_button2link.url} className="c-listing__header-link c-btn c-btn--primary">{primaryData.listing_button2text.text}</a> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {itemsData.map((listItem, i) => {
                            if (listItem.image.url && listItem.listing_title.text) {
                                return (
                                    <ListingItem key={i} title={listItem.listing_title.text} imageUrl={listItem.image.url} />
                                )
                            }
                            else {
                                return null
                            }
                        })}
                    </div>
                </div>
            </div>
        )   
    }

  return null
}