import React from 'react'
import JobListItem from "../jobs/list-item";

export const FeaturedJobsSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;
  
  if (itemsData) {
    return (
      <div className="c-jobs-listing">
          <div className="container">
              <div className="row">
                  <div className="c-jobs-listing__header">
                      <h2 className="c-jobs-listing__header-title">{primaryData.featuredjobs_title.text}</h2>
                  </div>
              </div>
              <div className="row">
                {itemsData.map((job, i) => {
                  if (job) {
                    return (
                      <JobListItem key={i} job={job.featuredjobs_document.document} />
                    )
                  }
                  else {
                    return null
                  }
                })}
              </div>
          </div>
      </div>
    )
  }

  return null
}