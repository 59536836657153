import React from 'react'

export const LogosSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;

  if (itemsData) {
    return (
      <div className="c-logos">
          <div className="container">
              <div className="row">
                  <div className="c-logos__container">
                      <div className="c-logos__media-container">
                          {primaryData.logos_background_image.url ? 
                            <img src={primaryData.logos_background_image.url} alt="" className="c-logos__media" loading="lazy"/> :
                            <img src="/images/test/logos.png" alt="" className="c-logos__media" loading="lazy"/>
                          }
                      </div>
                      <div className="row">
                          <div className="c-logos__content">
                              <h2 className="c-logos__title">{primaryData.logos_title.text}</h2>
                              <div className="c-logos__grid">
                              {itemsData.map((logo, i) => {
                                if (logo.logos_image.url && logo.logos_name.text) {
                                  return (
                                    <img key={i} src={`${logo.logos_image.url}&w=300`} alt={logo.logos_name.text} className="c-logos__logo" loading="lazy" />
                                  )
                                }
                                else {
                                  return null
                                }
                              })}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }

  return null
}