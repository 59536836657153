import React from 'react'
import { RichText } from 'prismic-reactjs'
import CustomLink from '../../utils/prismic-content-link'

export const ContentRichTextSlice = ({ slice }) => {
  const primaryData = slice.primary;

  if (primaryData.richtextcontent_content.raw) {
    return (
        <div className="c-content">
            <div className="container">
                <div className="row">
                    <div className="c-content__container cms-content">
                        <RichText render={primaryData.richtextcontent_content.raw} serializeHyperlink={CustomLink} /> 
                    </div>
                </div>
            </div>
        </div>
    )
  }

  return null
}