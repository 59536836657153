import React from 'react'
import { LogosSlice } from './logos'
import { ContentMediaSlice } from './content-media'
import { CtaSlice } from './cta'
import { ContentRichTextSlice } from './content-richtext'
import { FeaturedProjectsSlice } from './projects-featured'
import { FeaturedBlogPostsSlice } from './blog-featured'
import { HowItWorksCarouselSlice } from './how-it-works-carousel'
import { ListingSlice } from './listing'
import { ServicesCarouselSlice } from './services-carousel'
import { TestimonialSlice } from './testimonial'
import { TestimonialCarouselSlice } from './testimonial-carousel'
import { UseCasesSlice } from './use-cases'
import { WhyWorkWithUsSlice } from './why-work-with-us'
import {FeaturedJobsSlice} from "./jobs-featured";

export const SliceZones = ({ slices }) => {
  if (slices === undefined) {
    return null;
  }

  const sliceComponents = {
    call_to_action: CtaSlice,
    content_with_media: ContentMediaSlice,
    featured_blog_posts: FeaturedBlogPostsSlice,
    featured_projects: FeaturedProjectsSlice,
    how_it_works_carousel: HowItWorksCarouselSlice,
    listing: ListingSlice,
    logos: LogosSlice,
    rich_text_content: ContentRichTextSlice,
    services_carousel: ServicesCarouselSlice,
    testimonial: TestimonialSlice,
    testimonial_carousel: TestimonialCarouselSlice,
    use_cases: UseCasesSlice,
    why_work_with_us: WhyWorkWithUsSlice,
    featured_jobs: FeaturedJobsSlice,
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }

    return null
  })
}