import React from 'react'

const JobListItem = ({ job }) => {
    if (job) {
        return (
            <div className="c-jobs-listing__item">

                <h3 className="c-jobs-listing__item-title">{job.data.title.text}</h3>
                <p className="c-jobs-listing__item-summary">{job.data.summary.text}</p>
                <a href={job.url} className="c-jobs-listing__item-btn c-btn c-btn--large">Read more</a>
            </div>
        )
    } 
    
    return null
}

export default JobListItem