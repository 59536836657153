import React from 'react'
import BlogRelatedPosts from '../blog/related-posts'

export const FeaturedBlogPostsSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;

  const posts = []
  
  itemsData.map((doc) => {
    return (
      posts.push(doc.featuredblogposts_document.document)
    )
  })

  if (itemsData) {
    return (
      <BlogRelatedPosts heading={primaryData.featuredblogposts_title.text} posts={posts} />
    )
  }

  return null
}