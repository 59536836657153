import React from 'react'
import BlogListItem from '../blog/list-item'
const { sampleSize } = require('lodash')

const BlogRelatedPosts = ({ heading = "Related", posts }) => {
    if (posts.length) {
        return (
            <div className="c-blog-listing c-blog-listing--featured">
                <div className="container">
                    <div className="row">
                        <div className="c-blog-listing__header">
                            <h2 className="c-blog-listing__header-title">{heading}</h2>
                            <a href={`/${posts[0].url.split('/')[1]}`} className="c-blog-listing__header-link c-btn c-btn--primary">Discover more</a>
                        </div>
                    </div>
                    <div className="row">
                        {sampleSize(posts, 3).map((post, i) => {
                            return (
                                <BlogListItem key={i} post={post} />
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default BlogRelatedPosts