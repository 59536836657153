import React from 'react'

export const CtaSlice = ({ slice }) => {
  const primaryData = slice.primary;
  
    if (primaryData.cta_title.text && primaryData.cta_button_link.url) {
        return (
            <div className="c-cta">
                <div className="container">
                    <div className="row">
                        <div className="c-cta__container">
                            <div className="c-cta__wrapper">
                                <div className="c-cta__content">
                                    {primaryData.cta_title.text ? 
                                        <h2 className="c-cta__title">{primaryData.cta_title.text}</h2> : null}
                                    {primaryData.cta_description.text ?
                                        <div className="c-cta__summary">
                                            {primaryData.cta_description.text}
                                        </div> : null }
                                    {primaryData.cta_button_text && primaryData.cta_button_link.url ?
                                        <div className="c-cta__btn-container">
                                            <a href={primaryData.cta_button_link.url} className="c-cta__btn c-btn c-btn--large">{primaryData.cta_button_text}</a>
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }

  return null
}