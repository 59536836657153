import React from 'react'
import ProjectListItem from '../projects/list-item'

export const FeaturedProjectsSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;
  
  if (itemsData) {
    return (
      <div className="c-project-listing c-project-listing--featured">
          <div className="container">
              <div className="row">
                  <div className="c-project-listing__header">
                      <h2 className="c-project-listing__header-title">{primaryData.featuredprojects_title.text}</h2>
                      <div className="c-project-listing__header-link-container">
                        {primaryData.featuredprojects_startprojectlink.url ? 
                          <a href={primaryData.featuredprojects_startprojectlink.url} className="c-project-listing__header-link c-btn c-btn--text">Start a Project</a> : null}
                          <a href="/projects/" className="c-project-listing__header-link c-btn c-btn--primary">See all Projects</a>
                      </div>
                  </div>
              </div>
              <div className="row">
                {itemsData.map((article, i) => {
                  if (article) {
                    return (
                      <ProjectListItem key={i} article={article.featuredprojects_document.document} displayTag={primaryData.display_tag} />
                    )
                  }
                  else {
                    return null
                  }
                })}
              </div>
          </div>
      </div>
    )
  }

  return null
}