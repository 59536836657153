import React from 'react'
import Slider from "react-slick"

export const CarouselNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <svg fill="none" viewBox="0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M10.407 20 9 18.533 17.187 10 9 1.467 10.407 0 20 9.999 10.407 20Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M0 11V9h18v2z"/></svg>
        </button>
    );
}

export const CarouselPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            <svg fill="none" viewBox="0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M9.593 0 11 1.467 2.813 10 11 18.533 9.593 20 0 10.001 9.593 0Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M20 9v2H2V9z"/></svg>
        </button>
    );
}

export const TestimonialCarouselSlice = ({ slice }) => {
  const primaryData = slice.primary;
  const itemsData = slice.items;
  
    if (itemsData) {
        const settings = {
            appendDots: dots => (
                <div>
                    <div className="row">
                        <div className="c-testimonial-carousel__dots">
                            <ul>{dots}</ul>
                        </div>
                    </div>
                </div>
            ),
            dots: true,
            fade: true,
            infinite: true,
            nextArrow: <CarouselNextArrow />,
            prevArrow: <CarouselPrevArrow />,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 1000
        };

        return (
            <div className="c-testimonial-carousel">
                <div className="container">
                    <div className="row">
                        <div className="c-testimonial-carousel__container">
                            <Slider {...settings}>
                                {itemsData.map((item, i) => {
                                    if (item.testimonialcarousel_quote.text) {
                                        return (
                                            <div key={i}>
                                                <div className="row">
                                                    <div className="c-testimonial-carousel__media-container">
                                                        {item.testimonialcarousel_image.url ? 
                                                            <img src={`${item.testimonialcarousel_image.url}&w=600`} alt="" className="c-testimonial-carousel__media" loading="lazy" /> : null}
                                                    </div>
                                                    <div className="c-testimonial-carousel__content-container">
                                                        <div className="c-testimonial-carousel__subtitle">{primaryData.testimonialcarousel_title.text}</div>
                                                        <h2 className="c-testimonial-carousel__title">{item.testimonialcarousel_title.text}</h2>
                                                        <blockquote className="c-testimonial-carousel__quote">
                                                            <p className="c-testimonial-carousel__quote-text">{item.testimonialcarousel_quote.text}</p>
                                                            {item.testimonialcarousel_author.text ? 
                                                                <footer className="c-testimonial-carousel__quote-footer">
                                                                    <div className="c-testimonial-carousel__quote-author">{item.testimonialcarousel_author.text}</div>
                                                                    {item.testimonialcarousel_authorjobtitle.text ? 
                                                                        <div className="c-testimonial-carousel__quote-title">{item.testimonialcarousel_authorjobtitle.text}</div> : null}
                                                                </footer> : null}
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                              })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }

  return null
}