import React from 'react'

export const TestimonialSlice = ({ slice }) => {
  const primaryData = slice.primary;
  
    if (primaryData.testimonial_quote.text) {
        return (
            <div className="c-testimonial">
                <div className="container">
                    <div className="row">
                        <div className="c-testimonial__container">
                            <div className="row">
                                <div className="c-testimonial__quote-container">
                                    <blockquote className="c-testimonial__quote">
                                        <p className="c-testimonial__quote-text">“{primaryData.testimonial_quote.text}”</p>
                                    </blockquote>
                                </div>
                                {primaryData.testimonial_link.url && primaryData.testimonial_link_text.text ? 
                                    <div className="c-testimonial__link-container">
                                        <a href={primaryData.testimonial_link.url} className="c-testimonial__link c-btn c-btn--text">{primaryData.testimonial_link_text.text}</a>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }

  return null
}